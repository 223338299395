import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Fade } from 'react-reveal';

export default function BackUp() {
  return (
    <div className={'back-up'}>
      <Fade delay={1500}>
      <a className={'icon yoooo'} onClick={() => scrollTo('body')} href="javascript:;">
        &#xe909;
      </a>
      </Fade>
    </div>
  );
}