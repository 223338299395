import React from "react";
import { Fade } from 'react-reveal';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function ProjectNav() {
  return (
    <Fade right cascade duration={500}>
    <ul className={'index-nav'}>
      <li className={'cake-a'}><AniLink paintDrip duration={.75} hex="#f2700f" to="/cake">Cake</AniLink></li>

      <li className={'sysco-a'}><AniLink paintDrip duration={.75} hex="#008CD2" to="/sysco">Sysco</AniLink></li>
      
      <li className={'cake-a'}><AniLink paintDrip duration={.75} hex="#f2700f" to="/cake-apps">Cake Apps</AniLink></li>
      
      <li className={'moviepass-a'}><AniLink paintDrip duration={.75} hex="#B81B0A" to="/moviepass">Moviepass</AniLink></li>
      
      <li className={'crunchyroll-a'}><AniLink paintDrip duration={.75} hex="#df6300" to="/crunchyroll">Crunchyroll</AniLink></li>
    </ul>
    </Fade>
  )
}